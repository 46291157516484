import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 15,
    title: "GraphQL e React",
    desc: "come mettere in piedi una Data-Driven App",
    img: "/blog-image/GraphQL.png",
    page: "blog/react-GraphQL",
    data: "20 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Applicazioni dinamiche e reattive sono alla base dello sviluppo web moderno. A questo obiettivo sono legate 2 tecnologie ampiamente utilizzate: GraphQL e React.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Applicazioni dinamiche e reattive sono
                                        alla base dello sviluppo web moderno - a
                                        questo obiettivo sono legate 2
                                        tecnologie ampiamente utilizzate:
                                        GraphQL e React. Analizziamo ora come
                                        sfruttare al meglio questi tool per
                                        generare una "Data-Driven App".
                                    </p>

                                    <h3>GraphQL</h3>

                                    <p>
                                        GraphQL è un linguaggio di
                                        interrogazione per le API che offre
                                        un'alternativa più efficiente ed
                                        espressiva alle tradizionali API REST. A
                                        differenza delle API REST - dove è
                                        necessario effettuare più chiamate per
                                        ottenere i dati desiderati - GraphQL
                                        consente agli sviluppatori di richiedere
                                        esattamente ciò di cui hanno bisogno in
                                        una sola chiamata. Questo approccio
                                        riduce il sovraccarico di rete e
                                        semplifica la gestione dei dati
                                        nell’applicazione.
                                    </p>
                                    <p>
                                        Un'altra caratteristica fondamentale di
                                        GraphQL è la sua flessibilità. Gli
                                        sviluppatori possono definire i propri
                                        schemi di dati personalizzati, che
                                        rappresentano la struttura dei dati
                                        della loro applicazione. Questo
                                        significa che si possono ottenere dati
                                        esatti di cui si ha bisogno senza dover
                                        dipendere da endpoint API predefiniti.
                                    </p>

                                    <h5>
                                        React - il framework per interfacce
                                        utente reattive
                                    </h5>
                                    <p>
                                        React è uno dei framework JavaScript più
                                        diffusi per la creazione di interfacce
                                        utente dinamiche e reattive. La sua
                                        architettura basata su componenti
                                        consente di creare applicazioni
                                        modulari, suddividendo l'interfaccia
                                        utente in piccoli componenti
                                        riutilizzabili. Questo approccio
                                        semplifica la manutenzione del codice e
                                        migliora la leggibilità.
                                    </p>
                                    <p>
                                        Un altro vantaggio di React è il suo
                                        approccio dichiarativo alla gestione
                                        dello stato dell'applicazione. Gli
                                        sviluppatori definiscono come dovrebbe
                                        apparire l'interfaccia utente in base
                                        allo stato corrente dell'applicazione e
                                        React si occupa automaticamente di
                                        aggiornare l'interfaccia quando lo stato
                                        cambia. Questo elimina la necessità di
                                        manipolare direttamente il DOM, rendendo
                                        il codice più sicuro e prevenendo errori
                                        comuni.
                                    </p>
                                    <h5>GraphQL e React</h5>
                                    <p>
                                        Vediamo ora come unire queste due
                                        tecnologie per creare una Data-Driven
                                        App:
                                    </p>
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <p>
                                        <b>
                                            1. Creazione di uno schema GraphQL
                                        </b>
                                    </p>
                                    <p>
                                        Il primo passo è definire uno schema
                                        GraphQL che rappresenti la struttura dei
                                        dati dell’applicazione. Questo schema
                                        definirà i tipi di dati e le operazioni
                                        di interrogazione che i client possono
                                        eseguire. Puoi utilizzare strumenti come
                                        Apollo Server o GraphQL Yoga per creare
                                        il tuo server GraphQL e definire lo
                                        schema.
                                    </p>
                                    <p>
                                        <b>
                                            2. Integrazione di React con GraphQL
                                        </b>
                                    </p>
                                    <p>
                                        Una volta creato lo schema GraphQL, è il
                                        momento di integrarlo nella tua
                                        applicazione React. Puoi utilizzare
                                        librerie come Apollo Client o Relay
                                        Modern per gestire le query GraphQL
                                        nella tua applicazione React. Queste
                                        librerie semplificano il processo di
                                        invio delle query al tuo server GraphQL
                                        e di gestione dei dati ricevuti.
                                    </p>

                                    <p>
                                        <b>3. Creazione di componenti React</b>
                                    </p>
                                    <p>
                                        Ora si possono creare i componenti React
                                        che utilizzano i dati provenienti dalle
                                        query GraphQL. Ogni componente dovrebbe
                                        rappresentare una parte specifica
                                        dell'interfaccia utente e utilizzare i
                                        dati in modo appropriato. Puoi passare i
                                        dati ottenuti dalle query come proprietà
                                        ai tuoi componenti e renderizzare
                                        dinamicamente il contenuto in base a
                                        questi dati.
                                    </p>
                                    <p>
                                        <b>4. Gestione dello stato locale</b>
                                    </p>
                                    <p>
                                        Mentre GraphQL gestisce la maggior parte
                                        dei dati remoti dell’applicazione,
                                        potrebbe esserci la necessità di gestire
                                        uno stato locale per le interazioni
                                        utente o i dati temporanei. Puoi
                                        utilizzare lo stato di React o librerie
                                        di gestione dello stato come Redux per
                                        gestire questo stato locale e mantenere
                                        la coerenza tra i componenti.
                                    </p>
                                    <p>
                                        <b>
                                            5. Ottimizzazione delle prestazioni
                                        </b>
                                    </p>
                                    <p>
                                        Un aspetto importante nella creazione di
                                        una Data-Driven App è l'ottimizzazione
                                        delle prestazioni. GraphQL consente di
                                        richiedere solo i dati necessari, ma è
                                        comunque importante evitare query
                                        complesse o ridondanti che potrebbero
                                        rallentare l'applicazione. Inoltre, è
                                        consigliabile implementare la
                                        memorizzazione nella cache dei dati per
                                        evitare chiamate inutili al server.
                                    </p>
                                    <p>
                                        Unire GraphQL e React per creare una
                                        Data-Driven App è un approccio potente
                                        per sviluppare applicazioni web
                                        dinamiche e reattive. GraphQL semplifica
                                        la gestione dei dati, consentendo di
                                        ottenere esattamente ciò di cui hai
                                        bisogno, mentre React semplifica la
                                        creazione di interfacce utente reattive
                                        e modulari. Vuoi sviluppare una web app
                                        di successo? Richiedici una consulenza,
                                        realizziamo per il tuo business
                                        applicazioni su misura performanti e
                                        flessibili, ottimizzando l’esperienza
                                        degli utenti.
                                    </p>
                                    <p>Buona programmazione!</p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
